.container {
    display: flex;
    align-items: center;
  }
  
  .left-panel {
    flex: 1;
    text-align: center;
    padding-right: 15px;
  }
  
  .right-panel {
    flex: 2;
    padding-left: 20px; /* Add padding for spacing */
    width: 100%;
    margin-bottom: 50px;
  }
  
  .label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px; /* Add margin below the label */
  }
  
  .content {
    /* Your content styling here */
    margin: auto;
    margin-top: 10px;
    height: 400px;
    overflow-y: auto;
  }

  /* Media query for mobile */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack panels vertically on mobile */
  }

  .left-panel {
    width: 100%;
    display: flex; /* Display items next to each other on mobile */
    justify-content: space-evenly; /* Space items horizontally */
    align-items: center;
    
  }

  .right-panel{
    padding-left: 0;
  }
}
  