.circular-button {
    width: 300px; /* Set the desired width and height for a circular shape */
    height: 300px;
    border-radius: 50%; /* Make it circular */
    background-color: #159542; /* Set the background color */
    color: whitesmoke; /* Set the text color */
    border: none; /* Remove the default button border */
    cursor: pointer; /* Add a pointer cursor on hover */
    outline: none; /* Remove the button outline on click */
    transition: background-color 0.3s ease; /* Add a smooth hover effect */
  }
  
  .circular-button:hover {
    background-color: #0f7c35; /* Change the background color on hover */
  }
  