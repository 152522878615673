/* Tab Component Styles */

/* Tabs container */
.tabs {
    display: flex;
    background-color: #000;
    color: whitesmoke;
    border-radius: 8px;
    
  }
  
  /* Tab button */
  .tab {
    flex: 1;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    background-color: #000;
    color: whitesmoke;
    border: none;
    outline: none;
    border-radius: 8px;
  }
  
  /* Selected tab */
  .tab.active {
    background-color: #333; /* Dark grey */
  }
  

  /* Responsive styles for mobile */
@media (max-width: 768px) {
  /* Center the tab buttons vertically */
  .tabs {
    align-items: center;
  }

  /* Adjust padding and margin for mobile */
  .tab {
    font-size: 12px;
  }
}
  