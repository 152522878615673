.table-container {
    width: auto;
    max-height: 400px; 
    overflow: auto; /* Add scrollbars when content overflows */
    padding: 10px;
  }

  @media (max-width: 768px) {
    
    .table-container{
      width: auto;
    }
  }