.profile-picture {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
}

.profile-picture-medium {
    width: 10vh;
    height: 10vh;
    
    border-radius: 50%;
}

.profile-picture-large {
  width: 250px; /* Adjust the width as needed */
  height: 250px; /* Adjust the height as needed */
  border-radius: 50%;
}

.profile-comparison {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center;
    padding-bottom: 10px;
  }

.arrow {
    font-size: 50px; /* Adjust the arrow size */
    padding-left: 25px;
    padding-right: 25px;
  }

  .profile-picture-large {
    width: 250px; /* Adjust the width as needed */
    height: 250px; /* Adjust the height as needed */
    border-radius: 50%;
  }


