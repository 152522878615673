/* Common button styles */
.friend-button {
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 150px; 
    transition: background-color 0.3s, color 0.3s; /* Add transition effect */
}

/* Styles for the "Add Friend" button */
.add-friend-button {
    background-color:  #1DB954; /* Spotify Green */
}

/* Hover effect for "Add Friend" button */
.add-friend-button:hover {
    background-color: #348838; /* Slightly darker green on hover */
}

/* Styles for the "Cancel" button */
.cancel-button {
    background-color: transparent;
    border: 2px solid #f44336; 
    color: #f44336; 
}

/* Hover effect for "Cancel" button */
.cancel-button:hover {
    background-color: #f44336; /* Red background on hover */
    color: white; /* White text color on hover */
}

/* Updated styles for the "Add Friends" button */
.add-friends-button {
    background-color: transparent; 
    color: #1DB954; /* Spotify green text color */
    font-size: 32px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    transition: color 0.3s ease; /* Smooth color transition */
}

.add-friends-button:hover {
    color: #168f3a; /* Darker green on hover */
}

.circular-button {
    width: 150px; 
    height: 150px; 
    background-color: #1DB954; /* Spotify Green */
    border: none;
    border-radius: 50%; 
    color: white; 
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 25px;
    box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  
    /* Add a hover effect */
    &:hover {
      background-color: #168f3a;
      transform: scale(1.05); 
      box-shadow: 0px 9px 15px rgba(255, 255, 255, 0.387); /* Add a subtle shadow on hover */
    }
  }


  .share-page-button {
    width: 250px; 
    height: 250px;
    background-color: #1DB954; /* Spotify Green */
    border: none;
    border-radius: 50%; 
    color: white; 
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 25px;
    box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  
    /* Add a hover effect */
    &:hover {
      background-color: #168f3a;
      transform: scale(1.05); 
      box-shadow: 0px 9px 15px rgba(255, 255, 255, 0.387); /* Add a subtle shadow on hover */
    }
  }

/* Media query for smaller button on mobile */
@media (max-width: 768px) {
    .circular-button {
      width: 100px; /* Adjust width for mobile */
      height: 100px; /* Adjust height for mobile */
      font-size: 16px; /* Adjust font size for mobile */
      margin: 5px; /* Adjust margin for mobile */
    }
    
    /* Adjust hover styles for mobile */
    .circular-button:hover {
      background-color: #168f3a;
      transform: scale(1.05);
      box-shadow: 0px 9px 15px rgba(255, 255, 255, 0.387);
    }
  }
  

/* CSS */
.share-button {
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  background-color: transparent;
  border-color: #1DB954;
  color: #1DB954;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.share-button:hover {
  background-color: #1db954;
  color: #ffffff;
}
