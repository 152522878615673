/* CSS */
.dark-theme-modal {
    .modal-content {
      background-color: #333; /* Dark background color */
      color: whitesmoke; /* Light text color */
    }
  
  }
  
/* Adjust modal styles for mobile */
@media (max-width: 768px) {
  .dark-theme-modal {
    width: 90%; /* Reduce modal width on mobile */
    margin: auto;
    padding: auto;
  }
}

/* Center the modal content vertically and horizontally */
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Center the modal title text */
.modal-title {
  text-align: center;
}

  