.song-card {
    background-color: #333; /* Dark grey background */
    color: whitesmoke; /* White text color */
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0 2px 2px rgba(9, 93, 12, 0.43);
    overflow: hidden; /* Hide overflowing text */;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 95%;
    transition: transform 0.2s ease;
  }

  .spotify-logo {
    position: absolute;
    top: 10px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    width: 30px; /* Adjust the width and height as needed */
    height: 30px;
  }

  .spotify-logo-big {
    width: 4.3rem;
    align-content: left;


  }

    /* Add hover effect for song card */
    .song-card:hover {
      transform: scale(1.05); 
    }
  
  .album-cover {
    width: 125px; /* Adjust the width as needed */
    height: 125px; /* Adjust the height as needed */
    margin-right: 10px;
  }
  
  .song-details {
    flex: 1;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow-wrap: break-word;
    white-space: initial; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
    width: 90%;
    display: flex;
    justify-content: left;
    flex-direction: column;

  }
  
  .song-name {
    font-size: 1.25rem; /* Larger font size for song name */
    text-align: left;
    margin: 0;
  }
   
  .artist-names {
    font-size: 1rem; /* Slightly smaller font size for artist names */
    color: #b6b5b5; /* Lighter grey color for artist names */
    text-align: left;
    margin: 0;
  }

  .card-container {
    height: 70vh;
    overflow-y: auto;
    padding-top: 10px;
    overflow-x: hidden;
  }



  /* FriendCard.css */
  .friend-card {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 8px;
    background-color: #2d2d2d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease; /* Smooth transform transition */
    width: 95%;
  }
  
  /* Add hover effect for FriendCard */
  .friend-card:hover {
    transform: scale(1.05); 
  }

.friend-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.display-name {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.friend-id {
  font-size: 14px;
  color: #888;
}

.profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 16px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Stuff Below is for friends page */

/* CSS styles for the "My Friends" label and horizontal line */
.my-friends-label {
  display: flex;
  align-items: center;
  margin-right: 64px;
  font-weight: bold;
  color: #ccc;
  padding-top: 40px;
}

.my-friends-row {
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: space-between; /* Space items evenly across the row */
  margin-bottom: 20px; /* Add some space below the row */
}


.friends-list {
  height: 70vh;
  overflow-y: auto;
  overflow-x: visible;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line {
  border: none;
  border-top: 1px solid #ccc; /* Thin horizontal line */
  flex-grow: 1; /* Makes the line span the width of the container */
  margin-top: 0px; /* Adjust the margin as needed */
}


  /* Responsive styles for mobile */
  @media (max-width: 768px) {

    .song-card {
      width: 100%; /* Fixed width for the song card */
    }

  
  }


   